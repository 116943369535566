.logo-pattern-img {
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
}

.ig-call-to-action a {
  color: var(--color-tertiary-accent);
  margin: 1em;
  width: 50%;
  font-size: 200%;
}
