.loading-syrup {
  text-align: center;
  height: 80vh;
  line-height: 80vh;
}
.loading-syrup img {
  vertical-align: middle;
  max-width: 55%;
  max-height: 55%;
}

.loading-syrup.mini {
  height: 30vh;
  line-height: 30vh;
}

@media screen and (max-width: 767px) {
  .loading-syrup {
    text-align: center;
    height: 70vh;
    line-height: 70vh;
  }
  .loading-syrup img {
    vertical-align: middle;
    max-width: 75%;
    max-height: 75%;
  }
}
