/* themes */
/* NOTE: using complementary color scheme, using color-primary as base. */

/* NEW COLORS */

:root {
  --text-primary: black;
  --text-secondary: white;
  --background: #f1f0f0;
  --color-primary-accent: #e8a723;
  --color-secondary-accent: #333f22;
  --color-tertiary-accent: #bc4c27;
  --color-quaternary-accent: #333f22;

  --header-logo-color: #bc4c27;
}

[data-theme="dark"] {
  --text-primary: white;
  --text-secondary: #f1f0f0;
  --background: #333; /* Dark gray or black background */
  --color-primary-accent: #e8a723; /* Yellow or gold primary accent */
  --color-secondary-accent: #111; /* Very dark gray secondary accent */
  --color-tertiary-accent: #bc4c27; /* Dark orange tertiary accent */
  --color-quaternary-accent: #e8a723; /* Darker orange quaternary accent */

  --header-logo-color: white;
}

/* :root {
  --text-primary: black;
  --text-secondary: white;
  --background: #f1f0f0;
  --color-tertiary-accent: #bc4c27;
  --color-quaternary-accent: #ee7623;
  --color-secondary-accent: #333f22;
  --color-primary-accent: #e8a723;
}
[data-theme="dark"] {
  --background: #282c34;
  --text-primary: white;
  --text-secondary: black;
  --color-primary: #5a5a78;
  --color-primary-1: #8080c4;
  --color-primary-2: #a7a7c4;
  --accent: #c4b46c;
  --accent-dark: #78725a;
} */

/* fonts */

@font-face {
  font-family: "fraunces";
  src: url("../src/assets/fonts/Fraunces-VariableFont_SOFT\,WONK\,opsz\,wght.ttf");
}

@font-face {
  font-family: "frauncesItalic";
  src: url("../src/assets/fonts/Fraunces-Italic-VariableFont_SOFT\,WONK\,opsz\,wght.ttf");
}

/* Helvetica Neue Regular, Medium, and Thin */
@font-face {
  font-family: "helveticaReg";
  src: url("../src/assets/fonts/HelveticaNeue-Regular.otf");
}
@font-face {
  font-family: "helveticaMed";
  src: url("../src/assets/fonts/HelveticaNeue-Medium.otf");
}
@font-face {
  font-family: "helveticaThin";
  src: url("../src/assets/fonts/HelveticaNeue-Thin.otf");
}
/* END Helvetica Neue Regular, Medium, and Thin */

@font-face {
  font-family: "mainFont";
  src: url("../src/assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "logoFont";
  src: url("../src/assets/fonts/GreatVibes-Regular.ttf");
}
@font-face {
  font-family: "headerFont";
  src: url("../src/assets/fonts/Domine-VariableFont_wght.ttf");
}

/* layout used in routes/root.tsx */
.root {
  color: var(--text-primary);
  background-color: var(--background);
  /* font-family: "fraunces";
  font-variation-settings: "wdth" 600, "wght" 800, "opsz" 48; */
  overflow-y: hidden;
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
}

.content {
  padding-bottom: 2.5rem;
}

/* Loader CSS */
.spinning-loader {
  /* Center vertically and horizontally */
  display: flex;
  justify-content: center;
  padding: 5em;
}
.spinning-loader div {
  width: 80px;
  height: 80px;
  /* Center vertically */
  /* position: absolute; */
  /* top: 45%; */
}

h2,
h3,
h4 {
  font-family: "helveticaThin";
}

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-text {
  font-size: 70%;
}
