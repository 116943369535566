.search-bar {
  color: var(--text-primary);
}

.search-input input {
  width: 80%;
  display: inline;
  height: 60px;
  font-size: 150%;
  padding-left: 4em;
  border-radius: 0px;
  border-radius: 0px;
  border-top: solid black 1px;
  border-bottom: solid black 1px;
}
.search-input button {
  position: absolute;
  height: 60px;
  width: 20%;
  font-size: 150%;
  font-family: "fraunces";
  font-variation-settings: "wdth" 600, "wght" 800, "opsz" 48;
  color: var(--color-quaternary-accent);
  background: none;
  border-radius: 0px;
  border: solid black 1px;
}

.page-link {
  cursor: pointer;
}

.search-results-container .banner-image {
  object-fit: cover;
  width: 100%;
  height: 250px;
  transform: scaleX(-1);
}

.search-results {
  padding: 1em;
}

.search-bar input {
  border-radius: 45px;
  border: solid var(--color-primary-accent) 3px;
  width: 80%;
  display: inline;
  height: 60px;
  font-size: 150%;
  padding-left: 2em;
}

.search-bar.dark-mode input {
  border: solid white 3px;
}

.btn-secondary:hover {
  background-color: var(--color-primary-accent);
  border-color: var(--color-primary-accent);
}

.search-bar button {
  position: absolute;
  font-size: 150%;
  margin-left: 1em;
  border: solid var(--color-secondary-accent) 3px;
  border-radius: 45px;
  background-color: var(--color-secondary-accent);
  height: 60px;
  padding: 10px 5% 10px 5%;
}

.landing-page h1 {
  margin-top: 15%;
  font-family: "fraunces";
  font-variation-settings: "wdth" 600, "wght" 800, "opsz" 48;
  color: var(--color-quaternary-accent);
  font-size: 450%;
  margin-left: -17px;
}

.landing-page p {
  font-family: "helveticaThin";
  font-size: 300%;
}

.carousel-container {
  margin-top: 2em;
  margin-bottom: 2em;
  padding-top: 2em;
  border-top: solid 2px black;
}

.carousel-container h1 {
  font-family: "fraunces";
  font-variation-settings: "wdth" 600, "wght" 800, "opsz" 48;
  color: var(--color-quaternary-accent);
}

.recipe-card .recipe-photo {
  background-color: #000000;
}
.recipe-card .recipe-photo img {
  opacity: 0.88;
}

.carousel-sub-item:hover {
  .carousel-hover-text {
    display: inline;
  }

  .img-div {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    /* border: solid black 1px; */
    background-color: rgba(0, 0, 0, 0.05);
  }

  & div img {
    transition: 300ms;
    opacity: 0.2;
  }
}

.carousel-sub-item {
  cursor: pointer;
  width: 25%;
  display: inline-block;
}
.carousel-sub-item .img-div {
  background-color: #000000;
}
.carousel-sub-item div img {
  opacity: 0.88;
}

.carousel-hover-text {
  padding: 0 1em 0 1em;
  position: absolute;
  width: 25%;
  display: none;
  text-align: center;
  top: 35%;
  font-family: "fraunces";
  font-size: 100%;
  font-variation-settings: "wdth" 600, "wght" 800, "opsz" 48;
}

.carousel-author {
  font-size: 80%;
}

.carousel-control {
  opacity: 1;
}

.img-div {
  margin: 0 1em 0 1em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.carousel-control i {
  color: var(--color-secondary-accent);
  font-size: 300%;
}

.dark-mode i {
  color: white;
}

.carousel-control {
  width: 4em;
}

.carousel-inner {
  padding: 0 4em;
}

.carousel {
  margin: 1em 0;
}

@media screen and (max-width: 770px) {
  .search-bar input {
    width: 75%;
  }
}

/* Responsive */
@media screen and (max-width: 700px) {
  .search-bar {
    margin: 0 1em;
  }
  .landing-page-text {
    display: none;
  }
  .search-bar input {
    width: 100%;
    margin-bottom: 0.5em;
  }
  .search-bar button {
    width: 100%;
    display: block;
    margin: 0;
    position: relative;
  }
  .landing-page img {
    margin: 0 1.5em;
  }

  .carousel-control i {
    font-size: 200%;
  }
  .carousel-inner {
    padding: 0 3em;
  }
  .img-div {
    margin: 0 0.25em 0 0.25em;
  }
}

@media screen and (max-width: 1000px) {
  .carousel-hover-text {
    font-size: 100%;
  }
}

@media screen and (max-width: 850px) {
  .landing-page-text {
    display: none;
  }
}

@media screen and (max-width: 770px) {
  .carousel-container h1 {
    padding-left: 2em;
  }
}

@media screen and (max-width: 550px) {
  .search-input button {
    width: 30%;
  }
  .search-input input {
    width: 70%;
  }
}
