.header-logo {
  font-family: "fraunces";
  font-variation-settings: "wdth" 600, "wght" 800, "opsz" 48;
  color: var(--header-logo-color);
  font-size: 2em;
}

.navbar {
  background-color: var(--color-primary);
  width: 100%;
  padding: 5px 0 0 0;
}

.ip-logo {
  margin-right: 0.5em;
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}
.nav-button {
  border: none;
  margin-top: 2px;
  margin-right: 1em;
}

.nav-btn {
  color: var(--text-primary);
}

.username-div {
  display: inline-block;
  overflow: hidden;
  max-width: 200px;
}

.nav-container {
  width: 100%;
  border-bottom: solid var(--text-primary) 2px;
  height: 111px;
}

.create-recipe {
  padding-top: 15px;
  margin-left: 2em;
}

.nav-logo-box {
  position: absolute;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -15%);
  padding-top: 20px;
}
.nav-logo-box :hover {
  color: var(--color-secondary-accent);
}

.bi-person-circle {
  font-size: 150%;
  margin-right: 0.25em;
}

.dropdown-toggle {
  color: var(--text-primary);
}

.navbar-btn .btn {
  height: 55px;
  border-radius: 0px;
}
.navbar-btn .nav-btn:hover {
  border-bottom: solid var(--text-primary) 2px;
}

.sign-up {
  padding-top: 15px;
  margin-left: 1em;
}

.navbar-toggler {
  margin: 0.75em;
}

.profile-btn {
  float: right;
}
.large-screen-profile-menu {
  display: block;
}

.large-screen-profile-menu .dropdown-menu {
  left: auto;
}
.lil-screen-profile-menu {
  display: none;
}

.navbar-collapse {
  display: none;
}

.show-menu {
  display: block;
}

.navbar-hamburger {
  display: none;
  margin: 1em;
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: 100%;
  line-height: 1;
  color: var(--text-primary);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--text-primary);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

.navbar-hamburger-icon {
  font-size: 150%;
}

@media screen and (max-width: 766px) {
  .large-screen-profile-menu {
    display: none;
  }

  .lil-screen-profile-menu {
    display: block;
  }

  .nav-logo-box {
    position: relative;
    width: 100%;
    left: 0;
    transform: none;
    padding-top: 0;
  }

  .profile-btn {
    float: none;
  }

  .navbar-collapse {
    background-color: var(--background);
    z-index: 3;
    position: absolute;
    top: 72%;
    margin-top: 2em;
  }

  .create-recipe {
    padding-top: 15px;
    margin-left: 0;
  }

  .navbar-hamburger {
    display: block;
  }

  .header-logo {
    font-size: 1.8em;
  }
}
