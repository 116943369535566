.sign-up-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
  color: var(--text-primary);
  padding: 2em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-family: "fraunces";
}
.sign-up-container input.valid {
  border: 1px solid green;
}
.sign-up-container input.invalid {
  border: 1px solid red;
}

.password-help {
  font-size: 0.875em;
  margin-top: 1em;
}

.sign-up-container h2 {
  font-family: "helveticaThin";
}

.sign-up-container .btn-primary {
  width: 100%;
  margin-top: 10px;
  background: var(--color-primary-accent);
  font-family: "fraunces";
  font-size: 150%;
  font-variation-settings: "wdth" 600, "wght" 800, "opsz" 48;
  background: var(--background);
  border: solid var(--color-quaternary-accent) 2px;
  color: var(--color-quaternary-accent);
}

.sign-up-container .btn-primary:hover {
  background-color: var(--color-primary-accent);
  border-color: var(--color-tertiary-accent);
}

.welcome-message .btn {
  color: var(--text-primary);
}

.confirmation-code {
  text-align: center;
}
.confirmation-code label {
  margin-right: 0.5em;
}
.confirmation-code input {
  display: inline;
  width: 50%;
}

/* responsive */
@media screen and (max-width: 767px) {
  .sign-up-container {
    margin-top: 0;
    margin-bottom: 0;
  }
}
