.error {
  color: red;
}
.recipe-img {
  width: 100%;
  /* min-height: 200px; */
  text-align: center;
  object-fit: cover;
  height: auto;
  aspect-ratio: 1 / 1;
}

.recipe-img-placeholder div {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 3px dotted black;
  border-radius: 5em;
  width: 75%;
  padding: 25%;
}

.step-number-box {
  text-align: center;
  height: 25px;
  width: 25px;
  position: absolute;
  color: var(--text-primary);
  background-color: var(--accent-dark);
  border-radius: 50%;
  display: inline-block;
  padding-top: 1px;
  margin-left: 0;
}

.step-number-dot {
  height: 10px;
  width: 10px;
  position: absolute;
  background-color: var(--accent-dark);
  border-radius: 50%;
  display: inline-block;
  margin-left: 7.5px;
  margin-top: 7px;
}

.like-btn {
  color: var(--text-primary);
  font-size: 125%;
  position: absolute;
  right: 0%;
  bottom: 0;
}
.bi-heart-fill {
  color: var(--color-tertiary-accent);
}
.like-btn:hover {
  color: var(--color-primary-accent);
}

.recipe-info {
  font-family: "fraunces";
}

.recipe-info {
  position: relative;
  height: 100%;
  text-align: center;
}

.recipe-info div {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.recipe-info p {
  margin-bottom: 2em;
}

.recipe-info span {
  margin: 5px;
}

.recipe-info h1 {
  font-family: "fraunces";
}

.ingredient {
  margin: 0.5em 0;
}
.form-check-input {
  border: solid 1px var(--color-tertiary-accent);
}

.form-check-input:checked {
  background-color: var(--color-tertiary-accent);
  border-color: var(--color-tertiary-accent);
}

.step + hr {
  margin: 0.5em 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.step-number {
  text-align: center;
  margin: auto;
  font-size: 125%;
}

.edit-recipe-card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.edit-recipe-card .btn {
  color: var(--text-primary);
}

.submit-recipe-btn {
  color: var(--text-primary);
  border: solid var(--text-primary);
}

.recipe-card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  text-align: center;
  margin: 1em;
  cursor: pointer;
  background: var(--color-primary);
  color: var(--text-primary);

  & div {
    padding: 0;
  }
}

.description-text {
  color: black;
  background: var(--color-primary-accent);
  padding: 1em 10%;
  font-family: "fraunces";
  font-variation-settings: "wdth" 100, "wght" 500, "opsz" 48;
  font-size: 150%;
}

.recipe-body {
  font-family: "fraunces";
  padding: 0 10%;
}

.recipe-form label {
  margin-top: 1em;
}
.is-edit-page {
  .ingredient-label,
  .step,
  .recipe-tag {
    cursor: pointer;
  }
}
.btn-outline-secondary {
  color: var(--text-primary);
}
.recipe-notes {
  padding: 0.5em 10% 1.5em 10%;
  font-family: "fraunces";
}
.tag-window {
  height: 100px;
}
.category-tag:hover {
  background: var(--color-primary-accent);
}
.category-tag {
  cursor: pointer;
  font-size: 85%;
  border: 1px solid var(--text-primary);
  border-radius: 5px;
  padding: 0.2em;
  margin: 0.2em;
}

.recipe-tags {
  margin: 2.5em 0 1em 0;
  padding: 0.5em 10%;
  font-size: 125%;
  font-family: "fraunces";
  text-align: center;
  border-top: 2px solid var(--text-primary);
  border-bottom: 2px solid var(--text-primary);
}

.recipe-tag {
  white-space: nowrap;
}

.recipe-tag i {
  padding: 0 5%;
}

.recipe-author {
  font-size: 75%;
}

.recipe-utilities .btn {
  color: var(--text-primary);
}

/* responsive */
@media screen and (max-width: 767px) {
  .recipe-info {
    min-height: 100px;
    margin-top: 1em;
  }
}

@media screen and (max-width: 575px) {
  .step {
    text-align: center;
  }
}

/* Reorder when small screen */
@media screen and (max-width: 767px) {
  .recipe-card {
    display: flex;
    flex-flow: column;
  }
  .recipe-info {
    min-height: 0;
    margin-top: 2em;
    padding-bottom: 1em;
    margin-bottom: 1em;
  }
  .like-btn {
    bottom: 2.5em;
    right: 1em;
  }
  .two {
    order: 1;
  }
  .one {
    order: 2;
  }
}
