.contact-us {
  color: var(--text-primary);
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.contact-us h1 h2 {
  text-align: center;
  font-family: "helveticaThin";
}
.contact-us {
  padding: 2em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  background-color: var(--background);
}

.contact-us label {
  display: block;
  margin-bottom: 8px;
}

.contact-us textarea {
  height: 150px;
}

.contact-us button[type="submit"] {
  background-color: var(--color-primary-accent);
  color: var(--text-primary);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-us button[type="submit"]:hover {
  background-color: var(--color-primary-accent);
}

.go-back-btn {
  cursor: pointer;
}

/* responsive */
@media screen and (max-width: 767px) {
  .contact-us {
    margin-top: 0;
    margin-bottom: 0;
  }
}
