.footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3rem;
  font-family: "helveticaReg";
  font-size: 90%;
  background: var(--color-secondary-accent);
  text-align: center;
  color: var(--text-secondary);
}

.footer-container a {
  margin: 0.5rem;
  text-decoration: none;
  color: var(--text-secondary);
}

.footer-container a:hover {
  text-decoration: underline;
}

.footer-container .ig-icon {
  font-size: 110%;
}

/* responsive */
@media screen and (max-width: 450px) {
  .footer-container {
    height: 4rem;
  }
}
