.recover-password-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
  color: var(--text-primary);
  padding: 2em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-family: "fraunces";
}

.recover-password-container .btn-primary {
  width: 100%;
  margin-top: 10px;
  background: var(--color-primary-accent);
  font-family: "fraunces";
  font-size: 150%;
  font-variation-settings: "wdth" 600, "wght" 800, "opsz" 48;
  background: var(--background);
  border: solid var(--color-quaternary-accent) 2px;
  color: var(--color-quaternary-accent);
}

.recover-password-container .btn-primary:hover {
  background-color: var(--color-primary-accent);
  border-color: var(--color-tertiary-accent);
}
