.login-btn {
  margin-top: 10px;
  font-family: "fraunces";
  height: 60px;
  font-size: 150%;
  width: 85%;
  font-variation-settings: "wdth" 600, "wght" 800, "opsz" 48;
  color: var(--color-quaternary-accent);
  background: var(--background);
  border: solid var(--color-quaternary-accent) 2px;
}

.btn-primary:hover {
  background-color: var(--color-primary-accent);
  border-color: var(--color-tertiary-accent);
}

.accordion-header {
  font-family: "helveticaReg";
  font-size: 150%;
}

h1 {
  font-family: "helveticaThin";
}

.manage-profile div {
  z-index: 2;
}

.graphic-wave {
  z-index: 0;
  /* position: absolute; */
  bottom: 40px;
  left: -70px;
  width: 200%;
  height: 30vh;
  --s: 45px; /* the size */
  --c: var(--color-primary-accent);

  --_s: calc(2 * var(--s)) calc(2 * var(--s));
  --_g: 35.36% 35.36% at;
  --_c: #0000 66%, var(--color-tertiary-accent) 68% 70%, #0000 72%;
  background: 
  /* radial-gradient(var(--_g) 100% 25%, var(--_c)) var(--s) var(--s) /
      var(--_s), */ radial-gradient(
        var(--_g) 0 75%,
        var(--_c)
      )
      var(--s) var(--s) / var(--_s),
    radial-gradient(var(--_g) 100% 25%, var(--_c)) 0 0 / var(--_s),
    radial-gradient(var(--_g) 0 25%, var(--_c)) 0 0 / var(--_s),
    /* repeating-conic-gradient(var(--c) 0 25%, #0000 0 50%) 0 0 / var(--_s), */
      radial-gradient(var(--_c)) 0 calc(var(--s) / 2) / var(--s) var(--s)
      var(--c);
}

.accordion {
  position: absolute;
  width: 30%;
}

@media screen and (max-width: 770px) {
  .manage-profile h1 {
    padding-left: 1em;
  }
  .profile-view-img {
    display: none;
  }
  .graphic-wave {
    height: 300px;
    position: absolute;
    bottom: 0;
  }
  .accordion {
    width: 100%;
  }
}

.profile-btns {
  float: right;
  padding-right: 2em;
}

.validator-icons .valid {
  color: green;
}

.validator-icons {
  display: inline;
}
.login-message {
}

.login-container {
  color: var(--text-primary);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
