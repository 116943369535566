.privacy-policy a {
  color: var(--color-quaternary-accent);
}
.table-of-contents li a {
  color: var(--text-primary);
  font-family: "helveticaMed";
  text-decoration: none;
}
.table-of-contents li a:hover {
  color: var(--text-primary);
  cursor: pointer;
  text-decoration: underline;
}
